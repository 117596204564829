import React from "react";
import { createRoot } from "react-dom/client";
// import Swiper core and required modules
import { Navigation, Pagination, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const HomeComponent = () => {
    return (
        <div>
            <div className="stage">
                <Swiper
                    modules={[Navigation, Pagination, A11y]}
                    autoHeight={true}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    breakpoints={{
                        "640": {
                        "slidesPerView": 2
                        },
                        "992": {
                        "slidesPerView": 2
                        }
                    }} 
                >
                    <SwiperSlide>
                    <img src="/public/img/slider/1.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/slider/2.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/slider/3.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/slider/4.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/slider/5.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/slider/6.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/slider/7.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/slider/8.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/slider/9.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/slider/10.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/slider/11.jpg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/slider/12.jpg" alt="" />
                    </SwiperSlide>
                </Swiper>
            </div>
            {/* <div className="program__wrapper">
                <h2 className="program__wrapper-title">Program</h2>
                <div className="program__content">
                    <a className="program__content-link" href="/tepisi" target="_self" >
                        <div className="program__content-square">
                            <div className="program__content-img">
                                <img src="/public/img/home/tepisi.png" alt="" />
                                <div className="img-bg-icon"></div>
                            </div>
                            <h3>Tepisi</h3>
                            <h5>Toplina u Vašem domu</h5>
                        </div>
                    </a>
                    <a className="program__content-link" href="/itisoni" target="_self" >
                        <div className="program__content-square">
                            <div className="program__content-img">
                                <img src="/public/img/home/tepisoni.png" alt="" />
                                <div className="img-bg-icon" ></div>
                            </div>
                            <h3>Itisoni</h3>
                            <h5>Komfor u Vašem prostoru</h5>
                        </div>
                    </a>
                    <a className="program__content-link" href="/parketi" target="_self" >
                        <div className="program__content-square">
                            <div className="program__content-img">
                                <img src="/public/img/home/parket.png" alt="" />
                                <div className="img-bg-icon"></div>
                            </div>
                            <h3>Parketi</h3>
                            <h5>Lepota u harmoniji</h5>
                        </div>
                    </a>
                    <a className="program__content-link" href="/laminati" target="_self" >
                        <div className="program__content-square">
                            <div className="program__content-img">
                                <img src="/public/img/home/laminati.png" alt="" />
                                <div className="img-bg-icon"></div>
                            </div>
                            <h3>Laminati</h3>
                            <h5>Jedinstvo prirode</h5>
                        </div>
                    </a>
                    <a className="program__content-link" href="/vinilpodovi" target="_self" >
                        <div className="program__content-square">
                            <div className="program__content-img">
                                <img src="/public/img/home/vinil.png" alt="" />
                                <div className="img-bg-icon" ></div>
                            </div>
                            <h3>Vinil Podovi</h3>
                            <h5>Za svaki prostor</h5>
                        </div>
                    </a>
                </div>
            </div> */}
            <div className="services">
                <h2 className="services__title">Program</h2>
                <div className="services__teaser">
                    <div className="services__box">
                        <img src="/public/img/tepih.jpg" alt="tepih image" />
                    </div>
                    <div className="services__description">
                        <h3>Tepisi</h3>
                        <h5>Ukoliko želite da unesete malo svežine u životni prostor - tepisi su pravo rešenje koje će Vaš dom učiniti drugačijim i dati mu Vaš lični pečat. Pažljivo izabran, tepisi može naglasiti lepotu Vašeg parketa, laminata ili nameštaja. Naši tepisi su kvalitetini i praktični za održavanje. U svakom našem prodajnom objektu možete videti koji su tepisi trenutno na akciji i oduševiti se cenom. Dođite u naše prodajne objekte i uverite se.</h5>
                        <div className="wrap">
                            <a href="/tepisi" className="button">Otkrijte više</a>
                        </div>
                    </div>
                </div>
                <div className="services__teaser services__teaser--revers">
                    <div className="services__description">
                        <h3>Itisoni</h3>
                        <h5>Imamo veliku ponudu itisona naših vodećih, a i stranih proizvođača. Različite su vrste tkanja, visina, gustina i kvaliteta vlakna. Veliki izbor itisona za domaćinstvo i velika ponuda savršenih materijala za Vaše kancelarije i sve tipove poslovnih prostora. Dođite i uverite se u naš kvalitet, niske cene i odličnu uslugu.</h5>
                        <div className="wrap">
                            <a href="/itisoni" className="button">Otkrijte više</a>
                        </div>
                    </div>
                    <div className="services__box">
                        <img src="/public/img/itison.jpg" alt="itison image" />
                    </div>
                </div>
                <div className="services__teaser">
                    <div className="services__box">
                        <img src="/public/img/parket.jpg" alt="parket image" />
                    </div>
                    <div className="services__description">
                        <h3>Parketi</h3>
                        <h5>Mi smo obezbedili širok izbor parketa za Vas. Mat ili visoki sjaj, veliki format ili mali slog? Izbor je na vama, a mi imamo sve za vas na jednom mestu. Posetite naše prodajne objekte i uverite se zašto smo najbolji.</h5>
                        <div className="wrap">
                            <a href="/parketi" className="button">Otkrijte više</a>
                        </div>
                    </div>
                </div>
                <div className="services__teaser services__teaser--revers">
                    <div className="services__description">
                        <h3>Laminati</h3>
                        <h5>Laminat je elegantna, moderna podna obloga, koja se brzo razvija. Naši laminati su kvalitetni i lepi, odgovarajući i za stambene i za poslovne prostorije. U ponudi je nekoliko kvaliteta sa različitim stepenima otpornosti. Posetite nas. U svakom prodajnom objektu možete naići i na akcijske cene. Čekamo Vas.</h5>
                        <div className="wrap">
                            <a href="/laminati" className="button">Otkrijte više</a>
                        </div>
                    </div>
                    <div className="services__box">
                        <img src="/public/img/laminat.jpg" alt="laminat image" />
                    </div>
                </div>
                <div className="services__teaser">
                    <div className="services__box">
                        <img src="/public/img/vinil.jpg" alt="vinil image" />
                    </div>
                    <div className="services__description">
                        <h3>Vinil Podovi</h3>
                        <h5>Vinil podovi su dostupni u širokom spektru boja i dezena. Izuzetno su udobni pri hodu i pružaju osećaj topline pod nogama. Vinil podne obloge su visokootporne i praktične. Apsorbuju buku koja nastaje pri hodu, jednostavno se ugrađuju i održavaju. Ekonomične su. Pružamo Vam veliki izbor Vinil podova, u svim našim prodajnim objektima. Posetite nas.</h5>
                        <div className="wrap">
                            <a href="/vinilpodovi" className="button">Otkrijte više</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services">
                <h2 className="services__title">Usluge</h2>
                <div className="services__teaser">
                    <div className="services__box">
                        <img src="/public/img/machine.jpg" alt="teaser image" />
                    </div>
                    <div className="services__description">
                        <h3>Usluga opšivanja</h3>
                        <h5> Uz kupljeni tepih, tepison ili tepih stazu – besplatno opšivanje. Opšivanje ivice tepiha sa različitom vrstom konca, svih boja i materijala. Imamo konac prirodnih vlakana kao sto je pamuk, vuna i svila i sintetički konac. Opšivanje ivica radimo po najpovoljnijim cenama u Pančevu.</h5>
                    </div>
                </div>
                <div className="services__teaser services__teaser--revers">
                    <div className="services__description">
                        <h3>Usluga ugradnje</h3>
                        <h5>Ugradnja za 1 dan! Profesionalna ugradnja tepisona, vinila, laminata i parketa u vašem domu. Savremena tehnologija izrade parketa omogućava unapređenje prirodnih karakteristika koje drvo kao materijal ima dajući za rezultat proizvod koji ima dimenzionalnu stabilnost, lako se ugrađuje i vrlo jednostavno održava. Zahvaljujući jedinstvenom sistemu spajanja, postavljanje laminata je brzo i jednostavno. U slučaju tepisona, postavljanjem tepisona od zida do zida zadržaćete toplinu Vašeg doma, povećaćete sigurnost i umanjiti buku. Zahvaljujući svojim izuzetnim osobinama i gipkosti, vinil podne obloge apsorbuju najveći deo buke koja nastaje pri hodu.</h5>
                    </div>
                    <div className="services__box">
                        <img src="/public/img/installe.jpg" alt="teaser image" />
                    </div>
                </div>
                <div className="services__teaser">
                    <div className="services__box">
                        <img src="/public/img/delivery.jpeg" alt="teaser image" />
                    </div>
                    <div className="services__description">
                        <h3>Usluga dostave robe</h3>
                        <h5>Brzo i profesionalno. Dostava robe na kućnu adresu. Kupivinom nekog od naših proizvoda (tepisi, tepisoni, parketi, laminati i vinil podovi) dobijate besplatnu dostavu na teritoriji opštine Pančevo. Rok isporuke se dogovara prilikom kupovine proizvoda. Posetite nas u nekom od naših prodajnih objekata i uverite se u naš kvalitet i naše usluge.</h5>
                    </div>
                </div>
            </div>
            <div className="our__partners">
                <h2 className="our__partners-title">Naši Partneri</h2>
                <Swiper
                    modules={[Navigation, Pagination, A11y]}
                    spaceBetween={40}
                    pagination={{clickable: true,}}
                    navigation
                    breakpoints={{
                        "200": {
                            "slidesPerView": 1
                        },
                        "640": {
                        "slidesPerView": 3
                        },
                        "992": {
                        "slidesPerView": 5
                        }
                    }} 
                >
                    <SwiperSlide>
                        <img src="/public/img/our-clients/1.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/our-clients/2.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/our-clients/3.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/our-clients/4.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/our-clients/5.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/our-clients/6.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/our-clients/7.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/our-clients/8.png" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/public/img/our-clients/9.png" alt="" />
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

if(document.getElementById("home")) {
    const root = createRoot(document.getElementById("home"));
    root.render(<HomeComponent />);    
}
