import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { FiltersComponent, Cards } from "../utils/galeryutils";


const imgsFirst = [
      {title:"18550", tag:"ARENA_NEW", src:"/public/img/ambijent/Itisoni/ARENA_NEW/TH_ARENA_NEW_18550.jpg"},
      {title:"33450", tag:"ARENA_NEW", src:"/public/img/ambijent/Itisoni/ARENA_NEW/TH_ARENA_NEW_33450.jpg"},
      {title:"46750", tag:"ARENA_NEW", src:"/public/img/ambijent/Itisoni/ARENA_NEW/TH_ARENA_NEW_46750.jpg"},
      {title:"55650", tag:"ARENA_NEW", src:"/public/img/ambijent/Itisoni/ARENA_NEW/TH_ARENA_NEW_55650.jpg"},
      {title:"66850", tag:"ARENA_NEW", src:"/public/img/ambijent/Itisoni/ARENA_NEW/TH_ARENA_NEW_66850.jpg"},
      {title:"76750", tag:"ARENA_NEW", src:"/public/img/ambijent/Itisoni/ARENA_NEW/TH_ARENA_NEW_76750.jpg"},
      {title:"176EP", tag:"EKONOMIK_PLUS", src:"/public/img/ambijent/Itisoni/EKONOMIK_PLUS/TH_EKONOMIK_PLUS_176EP.jpg"},
      {title:"445EP", tag:"EKONOMIK_PLUS", src:"/public/img/ambijent/Itisoni/EKONOMIK_PLUS/TH_EKONOMIK_PLUS_445EP.jpg"},
      {title:"776EP", tag:"EKONOMIK_PLUS", src:"/public/img/ambijent/Itisoni/EKONOMIK_PLUS/TH_EKONOMIK_PLUS_776EP.jpg"},
      {title:"2162", tag:"EKONOMIK_PLUS", src:"/public/img/ambijent/Itisoni/EKONOMIK_PLUS/TH_EKONOMIK_PLUS_2162.jpg"},
      {title:"33753", tag:"EKVATOR_URB", src:"/public/img/ambijent/Itisoni/EKVATOR_URB/TH_EKVATOR_URB_33753.jpg"},
      {title:"63753", tag:"EKVATOR_URB", src:"/public/img/ambijent/Itisoni/EKVATOR_URB/TH_EKVATOR_URB_63753.jpg"},
      {title:"89453", tag:"EKVATOR_URB", src:"/public/img/ambijent/Itisoni/EKVATOR_URB/TH_EKVATOR_URB_89453.jpg"},
      {title:"902", tag:"ENERGY_URB", src:"/public/img/ambijent/Itisoni/ENERGY_URB/TH_ENERGY_URB_902.jpg"},
      {title:"919", tag:"ENERGY_URB", src:"/public/img/ambijent/Itisoni/ENERGY_URB/TH_ENERGY_URB_919.jpg"},
      {title:"966", tag:"ENERGY_URB", src:"/public/img/ambijent/Itisoni/ENERGY_URB/TH_ENERGY_URB_966.jpg"},
      {title:"33410", tag:"ENTER_URB", src:"/public/img/ambijent/Itisoni/ENTER_URB/TH_ENTER_URB_33410.jpg"},
      {title:"52510", tag:"ENTER_URB", src:"/public/img/ambijent/Itisoni/ENTER_URB/TH_ENTER_URB_52510.jpg"},
      {title:"66810", tag:"ENTER_URB", src:"/public/img/ambijent/Itisoni/ENTER_URB/TH_ENTER_URB_66810.jpg"},
      {title:"1202", tag:"FAVORIT", src:"/public/img/ambijent/Itisoni/FAVORIT/TH_FAVORIT_1202.jpg"},
      {title:"1204", tag:"FAVORIT", src:"/public/img/ambijent/Itisoni/FAVORIT/TH_FAVORIT_1204.jpg"},
      {title:"1211", tag:"FAVORIT", src:"/public/img/ambijent/Itisoni/FAVORIT/TH_FAVORIT_1211.jpg"},
      {title:"1212", tag:"FAVORIT", src:"/public/img/ambijent/Itisoni/FAVORIT/TH_FAVORIT_1212.jpg"},
      {title:"1213", tag:"FAVORIT", src:"/public/img/ambijent/Itisoni/FAVORIT/TH_FAVORIT_1213.jpg"},
      {title:"1202", tag:"Favorit_URB", src:"/public/img/ambijent/Itisoni/Favorit_URB/TH_FAVORIT_URB_1202.jpg"},
      {title:"1204", tag:"Favorit_URB", src:"/public/img/ambijent/Itisoni/Favorit_URB/TH_FAVORIT_URB_1204.jpg"},
      {title:"1211", tag:"Favorit_URB", src:"/public/img/ambijent/Itisoni/Favorit_URB/TH_FAVORIT_URB_1211.jpg"},
      {title:"1212", tag:"Favorit_URB", src:"/public/img/ambijent/Itisoni/Favorit_URB/TH_FAVORIT_URB_1212.jpg"},
      {title:"1213", tag:"Favorit_URB", src:"/public/img/ambijent/Itisoni/Favorit_URB/TH_FAVORIT_URB_1213.jpg"},
      {title:"33411", tag:"GLOBAL", src:"/public/img/ambijent/Itisoni/GLOBAL/TH_GLOBAL_URB_33411.jpg"},
      {title:"66811", tag:"GLOBAL", src:"/public/img/ambijent/Itisoni/GLOBAL/TH_GLOBAL_URB_66811.jpg"},
      {title:"1402", tag:"LIDER_URB", src:"/public/img/ambijent/Itisoni/LIDER_URB/TH_LIDER_URB_1402.jpg"},
      {title:"1411", tag:"LIDER_URB", src:"/public/img/ambijent/Itisoni/LIDER_URB/TH_LIDER_URB_1411.jpg"},
      {title:"1115", tag:"MERIDIAN_URB", src:"/public/img/ambijent/Itisoni/MERIDIAN_URB/TH_MERIDIAN_URB_1115.jpg"},
      {title:"1127", tag:"MERIDIAN_URB", src:"/public/img/ambijent/Itisoni/MERIDIAN_URB/TH_MERIDIAN_URB_1127.jpg"},
      {title:"1135", tag:"MERIDIAN_URB", src:"/public/img/ambijent/Itisoni/MERIDIAN_URB/TH_MERIDIAN_URB_1135.jpg"},
      {title:"1144", tag:"MERIDIAN_URB", src:"/public/img/ambijent/Itisoni/MERIDIAN_URB/TH_MERIDIAN_URB_1144.jpg"},
      {title:"1166", tag:"MERIDIAN_URB", src:"/public/img/ambijent/Itisoni/MERIDIAN_URB/TH_MERIDIAN_URB_1166.jpg"},
      {title:"1175", tag:"MERIDIAN_URB", src:"/public/img/ambijent/Itisoni/MERIDIAN_URB/TH_MERIDIAN_URB_1175.jpg"},
      {title:"1197", tag:"MERIDIAN_URB", src:"/public/img/ambijent/Itisoni/MERIDIAN_URB/TH_MERIDIAN_URB_1197.jpg"},
      {title:"802", tag:"REKORD_URB", src:"/public/img/ambijent/Itisoni/REKORD_URB/TH_REKORD_URB_802.jpg"},
      {title:"811", tag:"REKORD_URB", src:"/public/img/ambijent/Itisoni/REKORD_URB/TH_REKORD_URB_811.jpg"},
      {title:"866", tag:"REKORD_URB", src:"/public/img/ambijent/Itisoni/REKORD_URB/TH_REKORD_URB_866.jpg"},
      {title:"877", tag:"REKORD_URB", src:"/public/img/ambijent/Itisoni/REKORD_URB/TH_REKORD_URB_877.jpg"},
      {title:"702", tag:"SINTELON_STAZE_URB", src:"/public/img/ambijent/Itisoni/SINTELON_STAZE_URB/TH_SINTELON_STAZE_URB_702.jpg"},
      {title:"711", tag:"SINTELON_STAZE_URB", src:"/public/img/ambijent/Itisoni/SINTELON_STAZE_URB/TH_SINTELON_STAZE_URB_711.jpg"},
      {title:"712", tag:"SINTELON_STAZE_URB", src:"/public/img/ambijent/Itisoni/SINTELON_STAZE_URB/TH_SINTELON_STAZE_URB_712.jpg"},
      {title:"713", tag:"SINTELON_STAZE_URB", src:"/public/img/ambijent/Itisoni/SINTELON_STAZE_URB/TH_SINTELON_STAZE_URB_713.jpg"},
      {title:"759", tag:"SINTELON_STAZE_URB", src:"/public/img/ambijent/Itisoni/SINTELON_STAZE_URB/TH_SINTELON_STAZE_URB_759.jpg"},
      {title:"766", tag:"SINTELON_STAZE_URB", src:"/public/img/ambijent/Itisoni/SINTELON_STAZE_URB/TH_SINTELON_STAZE_URB_766.jpg"}
];


const filtersFirst = [
  {name:"ARENA_NEW", title:"ARENA NEW" ,status: true},
  {name:"EKONOMIK_PLUS", title:"EKONOMIK PLUS", status: false},
  {name:"EKVATOR_URB", title:"EKVATOR URB", status: false},
  {name:"ENERGY_URB", title:"ENERGY URB", status: false},
  {name:"ENTER_URB", title:"ENTER URB", status: false},
  {name:"FAVORIT", title:"FAVORIT", status: false},
  {name:"Favorit_URB", title:"Favorit URB", status: false},
  {name:"GLOBAL", title:"GLOBAL", status: false},
  {name:"LIDER_URB", title:"LIDER URB", status: false},
  {name:"MERIDIAN_URB", title:"MERIDIAN URB", status: false},
  {name:"REKORD_URB", title:"REKORD URB", status: false},
  {name:"SINTELON_STAZE_URB", title:"SINTELON STAZE URB", status: false},
];

const ItisoniComponent = () => {
    const [imgs, setImgs] = useState([...imgsFirst]);
    const [filters, setFilters] = useState([...filtersFirst]);

    useEffect(() => updateImgs(filtersFirst), []);

    const handleFilter = (e) => {
      e.preventDefault();
      let selectedIndex = e.target.value;
      if(!selectedIndex) {
        selectedIndex = e.currentTarget.dataset.index;      
      }
      
      let newFilters = filters.map((item, i) => {
          if (selectedIndex == i) {
            return { ...item, status: true };
          } else {
            return { ...item, status: false };
          }
      });
      setFilters(newFilters);
      updateImgs(newFilters);
  }
      
 
    const updateImgs = (filtersProperty) => {
        let newImgs = [];
        let a = 0;

        imgsFirst.forEach((img, imgKey) => { 
          filtersProperty.forEach((filter, filterKey) => {  
            if( img.tag == filter.name && filter.status ){
              newImgs[a] = img;
              a++;
            }
          })
        });

        setImgs(newImgs)
    }

    return (
        <div className="galery">
            <FiltersComponent
                onClick={handleFilter}
                handleChange={handleFilter}
                filters={filters} 
            />
            <Cards imgs = {imgs} />
        </div>
    )
}

if(document.getElementById("itisoni")) {
    const root = createRoot(document.getElementById("itisoni"));
    root.render(<ItisoniComponent />);    
}
