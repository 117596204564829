import React, { useState } from 'react';
import { createRoot } from "react-dom/client";

const HeaderComponent = () => {
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    }
   
    return (
        <div className="container">
            <header className={`header ${isActive ? 'header--active' : ''}`} >
                <div className="header__box">
                    <div className="header__wrapper">
                        <a href="/" >
                            <img className="header__image" src="/public/img/ambijent.jpg" />
                        </a>
                    </div>
                </div>
                <ul className="header__links">
                    <li className={`${ location.pathname === "/" ? "header__links--active" : ""}`} ><a href="/">O Nama</a><div className="grey-underline"></div></li>
                    <li className={`${location.pathname.match(/^\/tepisi/) ? "header__links--active" : ""}`} ><a href="/tepisi">Tepisi</a><div className="grey-underline"></div></li>
                    <li className={`${location.pathname.match(/^\/itisoni/) ? "header__links--active" : ""}`} ><a href="/itisoni">Itisoni</a><div className="grey-underline"></div></li>
                    <li className={`${location.pathname.match(/^\/parketi/) ? "header__links--active" : ""}`} ><a href="/parketi">Parketi</a><div className="grey-underline"></div></li>
                    <li className={`${location.pathname.match(/^\/laminati/) ? "header__links--active" : ""}`} ><a href="/laminati">Laminati</a><div className="grey-underline"></div></li>
                    <li className={`${location.pathname.match(/^\/vinilpodovi/) ? "header__links--active" : ""}`} ><a href="/vinilpodovi">Vinil Podovi</a><div className="grey-underline"></div></li>
                </ul>
                <div className="header__button" onClick={toggleClass}>
                    <span>
                        <i id="prva" className="bar"></i>
                        <i className="bar"></i>
                        <i id="druga" className="bar"></i>
                    </span>
                </div>
            </header>
        </div>
    )
}

export default HeaderComponent;

const root = createRoot(document.getElementById("header"));
root.render(<HeaderComponent />);
