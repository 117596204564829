import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { FiltersComponent, Cards } from "../utils/galeryutils";

const imgsFirst = [
    {title:"Crafted Oak", tag:"Easy_Line_832", src:"/public/img/ambijent/Laminati/Easy_Line_832/THH_LA_Easy_Line_832_H1055_Crafted_Oak.jpg"},
    {title:"Classic Walnut Red", tag:"Easy_Line_832", src:"/public/img/ambijent/Laminati/Easy_Line_832/THH_LA_Easy_Line_832_H2071_Classic_Walnut_Red.jpg"},
    {title:"Stone_Oak", tag:"Easy_Line_832", src:"/public/img/ambijent/Laminati/Easy_Line_832/THH_LA_Easy_Line_832_H2341_Stone_Oak.jpg"},
    {title:"Amber_Oak", tag:"Easy_Line_832", src:"/public/img/ambijent/Laminati/Easy_Line_832/THH_LA_Easy_Line_832_H2735_Amber_Oak.jpg"},
    {title:"Victoria_Oak_Vanille", tag:"Easy_Line_832", src:"/public/img/ambijent/Laminati/Easy_Line_832/THH_LA_Easy_Line_832_H2824_Victoria_Oak_Vanille.jpg"},
    {title:"Woodbridge_Oak", tag:"Easy_Line_832", src:"/public/img/ambijent/Laminati/Easy_Line_832/THH_LA_Easy_Line_832_H2970_Woodbridge_Oak.jpg"},
    {title:"Black_forest_Oak", tag:"Essentials_832", src:"/public/img/ambijent/Laminati/Essentials_832/THH_LA_Essentials_832_H1007_Black_forest_Oak.jpg"},
    {title:"Desert_Oak", tag:"Essentials_832", src:"/public/img/ambijent/Laminati/Essentials_832/THH_LA_Essentials_832_H2069_Desert_Oak.jpg"},
    {title:"Tundra_Oak_Spring", tag:"Essentials_832", src:"/public/img/ambijent/Laminati/Essentials_832/THH_LA_Essentials_832_H2102_Tundra_Oak_Spring.jpg"},
    {title:"Tundra_Oak_Autumn", tag:"Essentials_832", src:"/public/img/ambijent/Laminati/Essentials_832/THH_LA_Essentials_832_H2103_Tundra_Oak_Autumn.jpg"},
    {title:"Belmond_Oak_Grey", tag:"Essentials_832", src:"/public/img/ambijent/Laminati/Essentials_832/THH_LA_Essentials_832_H2833_Belmond_Oak_Grey.jpg"},
    {title:"Pallas_Oak", tag:"Essentials_832", src:"/public/img/ambijent/Laminati/Essentials_832/THH_LA_Essentials_832_H2863_Pallas_Oak.jpg"},
    {title:"Brown_Oak", tag:"Woodstock_832", src:"/public/img/ambijent/Laminati/Woodstock_832/THH_LA_Woodstock_832_4V_H2352_Brown_Oak.jpg"},
    {title:"Patina_Oak", tag:"Woodstock_832", src:"/public/img/ambijent/Laminati/Woodstock_832/THH_LA_Woodstock_832_4V_H2712_Patina_Oak.jpg"},
    {title:"Dark_Copper_Oak", tag:"Woodstock_832", src:"/public/img/ambijent/Laminati/Woodstock_832/THH_LA_Woodstock_832_4V_H2859_Dark_Copper_Oak.jpg"},
    {title:"Patagonia_Oak_Light", tag:"Woodstock_832", src:"/public/img/ambijent/Laminati/Woodstock_832/THH_LA_Woodstock_832_4V_H2861_Patagonia_Oak_Light.jpg"},
    {title:"Patio_Oak_Beige", tag:"Woodstock_832", src:"/public/img/ambijent/Laminati/Woodstock_832/THH_LA_Woodstock_832_H1055_Patio_Oak_Beige.jpg"},
    {title:"Islay_Oak", tag:"Woodstock_832", src:"/public/img/ambijent/Laminati/Woodstock_832/THH_LA_Woodstock_832_H2064_Islay_Oak.jpg"},
    {title:"Skye_Oak", tag:"Woodstock_832", src:"/public/img/ambijent/Laminati/Woodstock_832/THH_LA_Woodstock_832_H2066_Skye_Oak.jpg"},
    {title:"Chalet_Oak", tag:"Woodstock_832", src:"/public/img/ambijent/Laminati/Woodstock_832/THH_LA_Woodstock_832_H2856_Chalet_Oak.jpg"},
    {title:"Cabin_Oak", tag:"Woodstock_PRO_833", src:"/public/img/ambijent/Laminati/Woodstock_PRO_833/THH_LA_Woodstock_PRO_833_H1001_Cabin_Oak.jpg"},
    {title:"Pale_Oak", tag:"Woodstock_PRO_833", src:"/public/img/ambijent/Laminati/Woodstock_PRO_833/THH_LA_Woodstock_PRO_833_H2848_Pale_Oak.jpg"},
    {title:"Halifax_Oak_Sand", tag:"Woodstock_PRO_833", src:"/public/img/ambijent/Laminati/Woodstock_PRO_833/THH_LA_Woodstock_PRO_833_H2855_Halifax_Oak_Sand.jpg"},
    {title:"Mountain_Oak", tag:"Woodstock_PRO_833", src:"/public/img/ambijent/Laminati/Woodstock_PRO_833/THH_LA_Woodstock_PRO_833_H2970_Mountain_Oak.jpg"},
    {title:"Belvedere_Chevron", tag:"Woodstock_XL_832", src:"/public/img/ambijent/Laminati/Woodstock_XL_832/THH_LA_Woodstock_XL_832_H2923_Belvedere_Chevron.jpg"},
    {title:"Blacksmith_Oak_Natural", tag:"Woodstock_XL_1032", src:"/public/img/ambijent/Laminati/Woodstock_XL_1032/THH_LA_Woodstock_XL_1032_H2940_Blacksmith_Oak_Natural.jpg"},
    {title:"Blacksmith_Oak_Smoked", tag:"Woodstock_XL_1032", src:"/public/img/ambijent/Laminati/Woodstock_XL_1032/THH_LA_Woodstock_XL_1032_H2941_Blacksmith_Oak_Smoked.jpg"},
    {title:"Blacksmith_Oak_Aged", tag:"Woodstock_XL_1032", src:"/public/img/ambijent/Laminati/Woodstock_XL_1032/THH_LA_Woodstock_XL_1032_H2942_Blacksmith_Oak_Aged.jpg"}
];


const filtersFirst = [
    {name:"Easy_Line_832", title:"Easy Line 832" ,status: true},
    {name:"Essentials_832", title:"Essentials 832", status: false},
    {name:"Woodstock_832", title:"Woodstock 832", status: false},
    {name:"Woodstock_PRO_833", title:"Woodstock PRO 833", status: false},
    {name:"Woodstock_XL_832", title:"Woodstock XL 832", status: false},
    {name:"Woodstock_XL_1032", title:"Woodstock XL 1032", status: false}
];


const LaminatiComponent = () => {
    const [imgs, setImgs] = useState([...imgsFirst]);
    const [filters, setFilters] = useState([...filtersFirst]);

    useEffect(() => updateImgs(filtersFirst), []);

    const handleFilter = (e) => {
      e.preventDefault();
      let selectedIndex = e.target.value;
      if(!selectedIndex) {
        selectedIndex = e.currentTarget.dataset.index;      
      }
      
      let newFilters = filters.map((item, i) => {
          if (selectedIndex == i) {
            return { ...item, status: true };
          } else {
            return { ...item, status: false };
          }
      });
      setFilters(newFilters);
      updateImgs(newFilters);
  }
      
 
    const updateImgs = (filtersProperty) => {
        let newImgs = [];
        let a = 0;

        imgsFirst.forEach((img, imgKey) => { 
          filtersProperty.forEach((filter, filterKey) => {  
            if( img.tag == filter.name && filter.status ){
              newImgs[a] = img;
              a++;
            }
          })
        });

        setImgs(newImgs)
    }

    return (
        <div className="galery">
            <FiltersComponent
                onClick={handleFilter}
                handleChange={handleFilter}
                filters={filters} 
            />
            <Cards imgs = {imgs} />
        </div>
    )
}

if(document.getElementById("laminati")) {
    const root = createRoot(document.getElementById("laminati"));
    root.render(<LaminatiComponent />);    
}
