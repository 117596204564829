import React from 'react';
import { createRoot } from "react-dom/client";

const FooterComponent = () => {
    return (
        <>
            <div className="container">
                <div className="app__separator"></div>
            </div>
            <footer className="footer">
                <div className="container">
                    <div className="footer__content">
                        <h3>Želite da poslujete sa nama?</h3>
                        <div className="footer__content-info">
                            <h5>Radosav Jovanović</h5>
                            <h5>tr.ambijent@yahoo.com</h5>
                            <h5>064/202-5002</h5>
                            <h5>064/649-1380</h5>
                            <h5><a href="https://www.facebook.com/people/DOO-Ambijent-Podovi/100063575648487/" target="_blank" >Facebook strana</a></h5>
                        </div>
                        {/* <div className="footer__box">
                            <a  href="/" >
                                <div className="footer__wrapper">
                                    <img src="{{ asset('public/img/ambijent.jpg') }}" />
                                </div>
                            </a>
                        </div> */}
                    </div>
                </div>
                <div className="footer__separator"></div>
                <div className="container">
                    <div className="footer__contact">
                        <a className="footer__contact-link" target="_blank" href="https://www.google.com/maps/place/Laminat+i+parket+Pancevo/@44.8748636,20.6364383,18z/data=!4m6!3m5!1s0x475a7fb6ef644979:0x322221447f29162c!8m2!3d44.8747151!4d20.6360869!16s%2Fg%2F11q8sj9jm4" >
                            <div>
                                <h3>Prodajno Mesto 1</h3>
                                <h5>Dimitrije Tucovića 36, Pančevo <br/>Telefon: 013/345-005<br/>Mobilni: 064/649-1381<br/><br/></h5>
                            </div>
                        </a>
                        <a  className="footer__contact-link" target="_blank" href="https://www.google.com/maps/place/Ambijent+podovi+doo/@44.8777166,20.6518931,17z/data=!4m14!1m7!3m6!1s0x475a7ef204c14c0f:0xd6f059825d15f3fd!2zQnJhxIdlIEpvdmFub3ZpxIdhIDEwMiwgUGFuxI1ldm8!3b1!8m2!3d44.8763785!4d20.6493075!3m5!1s0x475a7e93ea84d6a5:0x16115e67cbc5d6a7!8m2!3d44.8793365!4d20.654626!16s%2Fg%2F11bbt5lzgm" >
                            <div>
                                <h3>Prodajno Mesto 2</h3>
                                <h5>Braće Jovanovića 102, Pančevo <br/>Telefon: 013/335-496<br/>Mobilni: 064/649-1383<br/><br/></h5>
                            </div>
                        </a>
                        <a  className="footer__contact-link" target="_blank" href="https://www.google.com/maps/place/Kozara%C4%8Dka+stara+40,+Pan%C4%8Devo+303106/@44.8728997,20.6762019,16.24z/data=!4m6!3m5!1s0x475a7f042461c9ed:0xd97907d9554e7d12!8m2!3d44.8745348!4d20.6793713!16s%2Fg%2F11kx2z3sqw" >
                            <div>
                                <h3>Prodajno Mesto 3</h3>
                                <h5>Kozaračka 40, Pančevo <br/>Telefon: 013/2522-515<br/>Mobilni: 064/649-1380<br/><span style={{marginLeft: '61px'}}>064/649-1386</span></h5>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="footer__copyright">
                    <h5>Copyright © 2023</h5>
                </div>
            </footer>
        </>
    )
}

export default FooterComponent;

const root = createRoot(document.getElementById("footer"));
root.render(<FooterComponent />);
