import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { FiltersComponent, Cards } from "../utils/galeryutils";

const imgsFirst = [
    {title:"Ash_Stone", tag:"Rumba", src:"/public/img/ambijent/Parketi/Rumba/TH_1_Strip_Ash_Stone.jpg"},
    {title:"Oak_Savanna_Premium", tag:"Rumba", src:"/public/img/ambijent/Parketi/Rumba/TH_1_Strip_Oak_Savanna_Premium.jpg"},
    {title:"Oak_Snow", tag:"Rumba", src:"/public/img/ambijent/Parketi/Rumba/TH_1_Strip_Oak_Snow.jpg"},
    {title:"Ash_Cave", tag:"Rumba", src:"/public/img/ambijent/Parketi/Rumba/TH_1_Strip_Rumba_Ash_Cave.jpg"},
    {title:"Oak_Copper", tag:"Rumba", src:"/public/img/ambijent/Parketi/Rumba/TH_1_Strip_Rumba_Oak_Copper.jpg"},
    {title:"Oak_Forest", tag:"Rumba", src:"/public/img/ambijent/Parketi/Rumba/TH_1_Strip_Rumba_Oak_Forest.jpg"},
    {title:"Oak_Iceberg", tag:"Rumba", src:"/public/img/ambijent/Parketi/Rumba/TH_1_Strip_Rumba_Oak_Iceberg.jpg"},
    {title:"Oak_Lava", tag:"Rumba", src:"/public/img/ambijent/Parketi/Rumba/TH_1_Strip_Rumba_Oak_Lava.jpg"},
    {title:"Oak_Monsoon", tag:"Rumba", src:"/public/img/ambijent/Parketi/Rumba/TH_1_Strip_Rumba_Oak_Monsoon.jpg"},
    {title:"Oak_Scandinavian", tag:"Rumba", src:"/public/img/ambijent/Parketi/Rumba/TH_1_Strip_Rumba_Oak_Scandinavian.jpg"},
    {title:"OAK_MODERN_GREY", tag:"Rumba", src:"/public/img/ambijent/Parketi/Rumba/TH_RU_RUMBA_OAK_MODERN_GREY.jpg"},
    {title:"AFRICAN_MAHOGANY", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_AFRICAN_MAHOGANY.jpg"},
    {title:"ASH_COGNAC", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_ASH_COGNAC.jpg"},
    {title:"Oak_Chocolate", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Oak_Chocolate.jpg"},
    {title:"OAK_COCOA", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_OAK_COCOA.jpg"},
    {title:"OAK_ICEBERG_BR", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_OAK_ICEBERG_BR.jpg"},
    {title:"Oak_Robust_white", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Oak_Robust_white.jpg"},
    {title:"Ash_Silky_White", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Ash_Silky_White.jpg"},
    {title:"Oak_Copper_Elegant", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Oak_Copper_Elegant.jpg"},
    {title:"Oak_Cotton", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Oak_Cotton.jpg"},
    {title:"Oak_Cream", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Oak_Cream.jpg"},
    {title:"Oak_Elegant_High_Gloss", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Oak_Elegant_High_Gloss.jpg"},
    {title:"Oak_Elegant_Matt", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Oak_Elegant_Matt.jpg"},
    {title:"Oak_Elegant_Shiny", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Oak_Elegant_Shiny.jpg"},
    {title:"Oak_Linen", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Oak_Linen.jpg"},
    {title:"Oak_Mocha", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Oak_Mocha.jpg"},
    {title:"Oak_Nordic_Elegant", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Oak_Nordic_Elegant.jpg"},
    {title:"Oak_Supreme_Matt", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Oak_Supreme_Matt.jpg"},
    {title:"Oak_Vivid", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_Salsa_Oak_Vivid.jpg"},
    {title:"WALNUT", tag:"Salsa", src:"/public/img/ambijent/Parketi/Salsa/TH_3_Strip_WALNUT.jpg"},
]

const filtersFirst = [
    {name:"Rumba", title:"Rumba" ,status: true},
    {name:"Salsa", title:"Salsa", status: false},
];

const ParketiComponent = () => {
    const [imgs, setImgs] = useState([...imgsFirst]);
    const [filters, setFilters] = useState([...filtersFirst]);

    useEffect(() => updateImgs(filtersFirst), []);

    const handleFilter = (e) => {
      e.preventDefault();
      let selectedIndex = e.target.value;
      if(!selectedIndex) {
        selectedIndex = e.currentTarget.dataset.index;      
      }
      
      let newFilters = filters.map((item, i) => {
          if (selectedIndex == i) {
            return { ...item, status: true };
          } else {
            return { ...item, status: false };
          }
      });
      setFilters(newFilters);
      updateImgs(newFilters);
  }
 
    const updateImgs = (filtersProperty) => {
        let newImgs = [];
        let a = 0;

        imgsFirst.forEach((img, imgKey) => { 
          filtersProperty.forEach((filter, filterKey) => {  
            if( img.tag == filter.name && filter.status ){
              newImgs[a] = img;
              a++;
            }
          })
        });

        setImgs(newImgs)
    }

    return (
        <div className="galery">
            <FiltersComponent
                onClick={handleFilter}
                handleChange={handleFilter}
                filters={filters} 
            />
            <Cards imgs = {imgs} />
        </div>
    )
}

if(document.getElementById("parketi")) {
    const root = createRoot(document.getElementById("parketi"));
    root.render(<ParketiComponent />);    
}
