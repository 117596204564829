export const FiltersComponent = ({onClick, handleChange, filters}) =>
  <div className="galery__wrapper">
      <ul className="galery__wrapper-list">
        {filters.map(
          (filter, i)=>
            <li key={i} data-index={i} onClick={onClick} >
              <input 
                id={filter.name} 
                type="checkbox" 
                checked={filter.status}
                onChange={() =>{}}
              />
              <label htmlFor={filter.name}>{filter.title.toLocaleUpperCase()}</label>
            </li>)}
      </ul>
      <select className="galery__wrapper-selec" name="filter" onChange={handleChange}>
        {filters.map((item, index) => {
          return <option key={"filter" + index} value={index}>{item.title.toLocaleUpperCase()}</option>
        })}
      </select>
    </div>

export const Cards = ({imgs}) =>
  <ul>
    {imgs.map(
      (img, i)=>
      <li key={i}>
        <figure>
          <img src={img.src} alt={img.title}/>
          <figcaption> 
            <h4>{img.title.replaceAll("_", " ").toLocaleUpperCase()}</h4>
            <h5>{img.tag.replaceAll("_", " ").toLocaleUpperCase()}</h5>
          </figcaption>
        </figure>
      </li>)}
  </ul>

export const TepihCards = ({imgs}) =>
  <ul>
    {imgs.map(
      (img, i)=>
      <li key={i}>
        <figure>
          <img src={img.src} alt={img.title}/>
          <figcaption> 
            <h4>{img.title.replaceAll("_", " ").toLocaleUpperCase()}</h4>
            <h5>Format:</h5>
            <div className="card__dimensions">
              {img?.dimensions.map(function(value, index) {
                return <h5 key={index}>{value}</h5>;
              })}
            </div>
          </figcaption>
        </figure>
      </li>)}
  </ul>