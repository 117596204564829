import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { FiltersComponent, TepihCards } from "../utils/galeryutils";

const imgsFirst = [
    {title:"01GKG", tag:"BOHO", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "0,8 x 1,5 m", "Staza 0,8 m"], src:"/public/img/ambijent/Tepisi/BOHO/THH_BOHO_01GKG.jpg"},
    {title:"08VKV", tag:"BOHO", dimensions:[ "1,6 x 2,3 m", "1,4 x 2 m"], src:"/public/img/ambijent/Tepisi/BOHO/THH_BOHO_08VKV.jpg"},
    {title:"36EME", tag:"BOHO", dimensions:[ "2 x 2,9 m", "1,6 x 2,3 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/BOHO/THH_BOHO_36EME.jpg"},
    {title:"56WEW", tag:"BOHO", dimensions:[ "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/BOHO/THH_Boho_56WEW.jpg"},
    {title:"59EME", tag:"BOHO", dimensions:[ "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "Staza 0,8 m"], src:"/public/img/ambijent/Tepisi/BOHO/THH_Boho_59EME.jpg"},
    {title:"02BFB", tag:"COLOR", dimensions:[ "2 x 1,33 m", "0,8 x 1,5 m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/COLOR/THH_COLOR_02BFB.jpg"},
    {title:"02EFE", tag:"COLOR", dimensions:[ "2 x 1,33 m", "0,8 x 1,5 m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/COLOR/THH_COLOR_02EFE.jpg"},
    {title:"02KFK", tag:"COLOR", dimensions:[ "2 x 1,33 m", "0,8 x 1,5 m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/COLOR/THH_COLOR_02KFK.jpg"},
    {title:"02LFL", tag:"COLOR", dimensions:[ "2 x 1,33 m", "0,8 x 1,5 m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/COLOR/THH_COLOR_02LFL.jpg"},
    {title:"02RFR", tag:"COLOR", dimensions:[ "2 x 1,33 m", "0,8 x 1,5 m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/COLOR/THH_COLOR_02RFR.jpg"},
    {title:"02WFW", tag:"COLOR", dimensions:[ "2 x 1,33 m", "0,8 x 1,5 m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/COLOR/THH_COLOR_02WFW.jpg"},
    {title:"16GWG", tag:"CREATIVE", dimensions:[ "2 x 2,9 m",, "1,6 x 2,3 m"], src:"/public/img/ambijent/Tepisi/CREATIVE/THH_CREATIVE_16GWG.jpg"},
    {title:"33EBE", tag:"CREATIVE", dimensions:[ "2 x 2,9 m",, "1,6 x 2,3 m"], src:"/public/img/ambijent/Tepisi/CREATIVE/THH_Creative_33EBE.jpg"},
    {title:"44GTG", tag:"CREATIVE", dimensions:[ "2 x 2,9 m",, "1,6 x 2,3 m"], src:"/public/img/ambijent/Tepisi/CREATIVE/THH_Creative_44GTG.jpg"},
    {title:"49GWG", tag:"CREATIVE", dimensions:[ "2 x 2,9 m",, "1,6 x 2,3 m",  "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/CREATIVE/THH_Creative_49GWG.jpg"},
    {title:"01BBB", tag:"DOLCE_VITA", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "Krug 0,8 m", "Krug 1,6 m"], src:"/public/img/ambijent/Tepisi/DOLCE_VITA/THH_DOLCE_VITA_01BBB.jpg"},
    {title:"01EEE", tag:"DOLCE_VITA", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "0,67 x 1,1 m", "Krug 0,8 m"], src:"/public/img/ambijent/Tepisi/DOLCE_VITA/THH_DOLCE_VITA_01EEE.jpg"},
    {title:"01LLL", tag:"DOLCE_VITA", dimensions: [ "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "0,67 x 1,1 m", "Krug 0,8 m"], src:"/public/img/ambijent/Tepisi/DOLCE_VITA/THH_DOLCE_VITA_01LLL.jpg"},
    {title:"01SSS", tag:"DOLCE_VITA", dimensions: [ "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "0,67 x 1,1 m", "Krug 0,8 m", "Staza 0,8 m"], src:"/public/img/ambijent/Tepisi/DOLCE_VITA/THH_DOLCE_VITA_01SSS.jpg"},
    {title:"01TTT", tag:"DOLCE_VITA", dimensions: [ "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "0,67 x 1,1 m", "Krug 0,8 m"], src:"/public/img/ambijent/Tepisi/DOLCE_VITA/THH_DOLCE_VITA_01TTT.jpg"},
    {title:"01WWW", tag:"DOLCE_VITA",  dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "Krug 0,8 m", "Krug 1,6 m"], src:"/public/img/ambijent/Tepisi/DOLCE_VITA/THH_DOLCE_VITA_01WWW.jpg"},
    {title:"05CFC", tag:"GATE", dimensions: [ "Staza 0,8 m", "Staza 0,67m"], src:"/public/img/ambijent/Tepisi/GATE/THH_GATE_05CFC.jpg"},
    {title:"05DFO", tag:"GATE", dimensions: [ "Staza 0,8 m", "Staza 0,67m"], src:"/public/img/ambijent/Tepisi/GATE/THH_GATE_05DFO.jpg"},
    {title:"05MFM", tag:"GATE", dimensions: [ "Staza 0,8 m", "Staza 0,67m"], src:"/public/img/ambijent/Tepisi/GATE/THH_GATE_05MFM.jpg"},
    {title:"05PFS", tag:"GATE", dimensions: [ "Staza 0,8 m", "Staza 0,67m"], src:"/public/img/ambijent/Tepisi/GATE/THH_GATE_05PFS.jpg"},
    {title:"01AFA", tag:"LOFT", dimensions: [ "0,8 x 1,5 m", "2 x 1,33m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/LOFT/THH_LOFT_01AFA.jpg"},
    {title:"01BFB", tag:"LOFT", dimensions: [ "0,8 x 1,5 m", "2 x 1,33m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/LOFT/THH_LOFT_01BFB.jpg"},
    {title:"01EFE", tag:"LOFT", dimensions: [ "0,8 x 1,5 m", "2 x 1,33m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/LOFT/THH_LOFT_01EFE.jpg"},
    {title:"01GFG", tag:"LOFT", dimensions: [ "0,8 x 1,5 m", "2 x 1,33m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/LOFT/THH_LOFT_01GFG.jpg"},
    {title:"01SFS", tag:"LOFT", dimensions: [ "0,8 x 1,5 m", "2 x 1,33m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/LOFT/THH_LOFT_01SFS.jpg"},
    {title:"01WFW", tag:"LOFT", dimensions: [ "0,8 x 1,5 m", "2 x 1,33m", "0,67 x 1,1 m"], src:"/public/img/ambijent/Tepisi/LOFT/THH_LOFT_01WFW.jpg"},
    {title:"05EBD", tag:"MONDO", dimensions: [ "2,4 x 3,4 m", "2 x 2,9 m", "1,6 x 2,3 m", "0,8 x 1,5 m", "Staza 0,8 m", "Staza 1 m"], src:"/public/img/ambijent/Tepisi/MONDO/THH_Mondo_05EBD.jpg"},
    {title:"12BHE", tag:"MONDO", dimensions: [ "2 x 2,9 m", "1,2 x 1,7 m", "1,6 x 2,3 m", "0,8 x 1,5 m"] ,src:"/public/img/ambijent/Tepisi/MONDO/THH_Mondo_12BHE.jpg"},
    {title:"30GWG", tag:"MONDO", dimensions: [ "2 x 2,9 m", "1,2 x 1,7 m", "1,6 x 2,3 m", "0,8 x 1,5 m", "Staza 0,8 m", "Staza 1 m"], src:"/public/img/ambijent/Tepisi/MONDO/THH_Mondo_30GWG.jpg"},
    {title:"32EHG", tag:"MONDO", dimensions: [ "2 x 2,9 m", "1,2 x 1,7 m", "1,6 x 2,3 m"], src:"/public/img/ambijent/Tepisi/MONDO/THH_Mondo_32EHG.jpg"},
    {title:"36WBG", tag:"MONDO", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "Staza 0,8 m", "Staza 1 m", "Runner 0,66m"], src:"/public/img/ambijent/Tepisi/MONDO/THH_Mondo_36WBG.jpg"},
    {title:"58DYH", tag:"MONDO", dimensions: [ "2 x 2,9 m", "1,2 x 1,7 m", "1,6 x 2,3 m", "0,8 x 1,5 m", "Staza 0,8 m", "Staza 1 m"], src:"/public/img/ambijent/Tepisi/MONDO/THH_Mondo_58DYH.jpg"},
    {title:"63EDB", tag:"MONDO", dimensions: [ "2 x 2,9 m", "1,2 x 1,7 m", "1,6 x 2,3 m", "0,8 x 1,5 m"],  src:"/public/img/ambijent/Tepisi/MONDO/THH_Mondo_63EDB.jpg"},
    {title:"71BME", tag:"MONDO", dimensions: [ "2 x 2,9 m", "2,4 x 3,4 m", "1,2 x 1,7 m", "1,6 x 2,3 m", "0,8 x 1,5 m", "Staza 0,8 m", "Staza 1 m", "Runner 0,66m"], src:"/public/img/ambijent/Tepisi/MONDO/THH_Mondo_71BME.jpg"},
    {title:"74GBE", tag:"MONDO", dimensions: [ "2 x 2,9 m", "1,2 x 1,7 m", "1,6 x 2,3 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/MONDO/THH_Mondo_74GBE.jpg"},
    {title:"90WGW", tag:"MONDO", dimensions: [ "2 x 2,9 m", "1,2 x 1,7 m", "1,6 x 2,3 m", "Staza 0,8 m", "Staza 1 m", "Runner 0,66m"], src:"/public/img/ambijent/Tepisi/MONDO/THH_Mondo_90WGW.jpg"},
    {title:"16OGO", tag:"PALERMO", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/PALERMO/THH_Palermo_16OGO.jpg"},
    {title:"25EHE", tag:"PALERMO", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/PALERMO/THH_Palermo_25EHE.jpg"},
    {title:"28GKG", tag:"PALERMO", dimensions: [ "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/PALERMO/THH_Palermo_28GKG.jpg"},
    {title:"30EHE", tag:"PALERMO", dimensions: [ "1,6 x 2,3 m", "1,4 x 2 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/PALERMO/THH_PALERMO_30EHE.jpg"},
    {title:"33KEK", tag:"PALERMO", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/PALERMO/THH_PALERMO_33KEK.jpg"},
    {title:"38WHW", tag:"PALERMO", dimensions: [ "1,6 x 2,3 m", "1,4 x 2 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/PALERMO/THH_PALERMO_38WHW.jpg"},
    {title:"41EKE", tag:"PALERMO", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/PALERMO/THH_PALERMO_41EKE.jpg"},
    {title:"42KGK", tag:"PALERMO", dimensions: [ "1,6 x 2,3 m", "1,4 x 2 m", "0,8 x 1,5 m"],  src:"/public/img/ambijent/Tepisi/PALERMO/THH_PALERMO_42KGK.jpg"},
    {title:"03DFO", tag:"PASSAGE", dimensions: [ "Staza 0,8 m", "Staza 0,67m"], src:"/public/img/ambijent/Tepisi/PASSAGE/THH_PASSAGE_03DFO.jpg"},
    {title:"03EFD", tag:"PASSAGE", dimensions: [ "Staza 0,8 m", "Staza 0,67m"], src:"/public/img/ambijent/Tepisi/PASSAGE/THH_PASSAGE_03EFD.jpg"},
    {title:"03OFD", tag:"PASSAGE", dimensions: [ "Staza 0,8 m", "Staza 0,67m"],  src:"/public/img/ambijent/Tepisi/PASSAGE/THH_PASSAGE_03OFD.jpg"},
    {title:"03VFO", tag:"PASSAGE", dimensions: [ "Staza 0,8 m", "Staza 0,67m"], src:"/public/img/ambijent/Tepisi/PASSAGE/THH_PASSAGE_03VFO.jpg"},
    {title:"28SGS", tag:"Pastel", dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"],  src:"/public/img/ambijent/Tepisi/Pastel/THH_PASTEL_28SGS.jpg"},
    {title:"30SKS", tag:"Pastel", dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/Pastel/THH_PASTEL_30SKS.jpg"},
    {title:"49GSG", tag:"Pastel_Kids", dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "Okrugli 1,2m"], src:"/public/img/ambijent/Tepisi/Pastel_Kids/THH_PASTEL_KIDS_49GSG.jpg"},
    {title:"52KVK", tag:"Pastel_Kids",  dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "Okrugli 1,2m"], src:"/public/img/ambijent/Tepisi/Pastel_Kids/THH_PASTEL_KIDS_52KVK.jpg"},
    {title:"59AMA", tag:"PLAY",  dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/PLAY/THH_PLAY_59AMA.jpg"},
    {title:"78PMP", tag:"PLAY",  dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"],  src:"/public/img/ambijent/Tepisi/PLAY/THH_PLAY_78PMP.jpg"},
    {title:"91PMP", tag:"PLAY",  dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/PLAY/THH_PLAY_91PMP.jpg"},
    {title:"C8RVR", tag:"PLAY",  dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/PLAY/THH_play_C8RVR.jpg"},
    {title:"E2AZA", tag:"PLAY", dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "Krug 1 m"], src:"/public/img/ambijent/Tepisi/PLAY/THH_PLAY_E2AZA.jpg"},
    {title:"01BWB", tag:"PLEASURE", dimensions: ["2 x 3 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "0,6 x 1,1 m", "0,6 x 2,3 m",  "0,8 x 2,5 m"], src:"/public/img/ambijent/Tepisi/PLEASURE/THH_PLEASURE_01BWB.jpg"},
    {title:"01EWE", tag:"PLEASURE", dimensions: ["2 x 3 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "0,6 x 1,1 m", "0,6 x 2,3 m",  "0,8 x 2,5 m"], src:"/public/img/ambijent/Tepisi/PLEASURE/THH_PLEASURE_01EWE.jpg"},
    {title:"01GGG", tag:"PLEASURE", dimensions: ["2 x 3 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "0,6 x 1,1 m", "0,6 x 2,3 m",  "0,8 x 2,5 m", "Krug 1,6 m", "Staza 0,8 m"], src:"/public/img/ambijent/Tepisi/PLEASURE/THH_PLEASURE_01GGG.jpg"},
    {title:"01GMG", tag:"PLEASURE", dimensions: ["2 x 3 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "0,6 x 1,1 m", "0,6 x 2,3 m",  "0,8 x 2,5 m"], src:"/public/img/ambijent/Tepisi/PLEASURE/THH_PLEASURE_01GMG.jpg"},
    {title:"01WWW", tag:"PLEASURE", dimensions: ["2 x 3 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "0,6 x 1,1 m", "0,6 x 2,3 m",  "0,8 x 2,5 m"], src:"/public/img/ambijent/Tepisi/PLEASURE/THH_PLEASURE_01WWW.jpg"},
    {title:"26CPC", tag:"PRACTICA", dimensions: ["2,4 x 3,4 m", "2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "Runner 0,66m", "Staza 0,8 m", "Staza 1 m", "Staza 1,2 m"], src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_26CPC.jpg"},
    {title:"26DPD", tag:"PRACTICA", dimensions: ["2,4 x 3,4 m", "2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "Runner 0,66m", "Staza 0,8 m", "Staza 1 m"], src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_26DPD.jpg"},
    {title:"38BPB", tag:"PRACTICA", dimensions: ["2,4 x 3,4 m", "2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "Runner 0,66m", "Staza 0,8 m", "Staza 1 m"], src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_38BPB.jpg"},
    {title:"53EBD", tag:"PRACTICA", dimensions: ["2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m",], src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_53EBD.jpg"},
    {title:"54DBD", tag:"PRACTICA", dimensions: ["2,4 x 3,4 m", "2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_54DBD.jpg"},
    {title:"82EED", tag:"PRACTICA", dimensions: ["2,4 x 3,4 m", "2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "Staza 0,8 m", "Staza 1 m", "Staza 1,2 m"], src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_82EED.jpg"},
    {title:"83EED", tag:"PRACTICA", dimensions: ["2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "Runner 0,66m", "Staza 0,8 m", "Staza 1 m"], src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_83EED.jpg"},
    {title:"98EDE", tag:"PRACTICA", dimensions: ["2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "0,8 x 1,5 m", "Runner 0,66m", "Staza 0,8 m"], src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_98EDE.jpg"},
    {title:"A1BEB", tag:"PRACTICA", dimensions: ["2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "Staza 0,8 m"],  src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_A1BEB.jpg"},
    {title:"A5BDB", tag:"PRACTICA", dimensions: ["2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "Staza 0,8 m"],  src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_A5BDB.jpg"},
    {title:"A6VMB", tag:"PRACTICA", dimensions: ["2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "Staza 0,8 m"],  src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_A6VMB.jpg"},
    {title:"A7BCV", tag:"PRACTICA", dimensions: ["2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "Staza 0,8 m"], src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_A7BCV.jpg"},
    {title:"A8VCD", tag:"PRACTICA", dimensions: ["2 x 3 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "Staza 0,8 m"], src:"/public/img/ambijent/Tepisi/PRACTICA/THH_PRACTICA_A8VCD.jpg"},
    {title:"04VDO", tag:"SAVANA_plus", dimensions: ["2 x 2,9 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/SAVANA_plus/THH_SAVANA_PLUS_04VDO.jpg"},
    {title:"04VMS", tag:"SAVANA_plus", dimensions: ["2 x 2,9 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/SAVANA_plus/THH_SAVANA_PLUS_04VMS.jpg"},
    {title:"18ODO", tag:"SAVANA_plus", dimensions: ["2 x 2,9 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/SAVANA_plus/THH_SAVANA_PLUS_18ODO.jpg"},
    {title:"20DOD", tag:"SAVANA_plus", dimensions: ["2 x 2,9 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/SAVANA_plus/THH_SAVANA_PLUS_20DOD.jpg"},
    {title:"20SVS", tag:"SAVANA_plus", dimensions: ["2 x 2,9 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/SAVANA_plus/THH_SAVANA_PLUS_20SVS.jpg"},
    {title:"31OVO", tag:"SAVANA_plus", dimensions: ["2 x 2,9 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/SAVANA_plus/THH_SAVANA_PLUS_31OVO.jpg"},
    {title:"34VSS", tag:"SAVANA_plus", dimensions: ["2 x 2,9 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/SAVANA_plus/THH_SAVANA_PLUS_34VSS.jpg"},
    {title:"35SMS", tag:"SAVANA_plus", dimensions: ["2 x 2,9 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/SAVANA_plus/THH_SAVANA_PLUS_35SMS.jpg"},
    {title:"37OSD", tag:"SAVANA_plus", dimensions: ["2 x 2,9 m", "1,6 x 2,3 m"," 1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/SAVANA_plus/THH_SAVANA_PLUS_37OSD.jpg"},
    {title:"01CCC", tag:"SOLID", dimensions: ["1,6 x 2,3 m","2 x 3 m"], src:"/public/img/ambijent/Tepisi/SOLID/THH_SOLID_01CCC.jpg"},
    {title:"01VPV", tag:"SOLID", dimensions: ["2,4 x 3,4 m", "2 x 3 m","2 x 1,33m", "1,6 x 2,3 m"], src:"/public/img/ambijent/Tepisi/SOLID/THH_SOLID_01VPV.jpg"},
    {title:"03CPC", tag:"SOLID", dimensions: ["2,4 x 3,4 m", "2 x 3 m", "1,6 x 2,3 m", "Staza 0,8 m", "Staza 1 m", "Staza 1,2 m"], src:"/public/img/ambijent/Tepisi/SOLID/THH_SOLID_03CPC.jpg"},
    {title:"12CVC", tag:"SOLID", dimensions: ["2 x 3 m", "1,6 x 2,3 m", "2 x 1,33 m", "0,8 x 1,5 m", "Staza 0,8 m", "Staza 1 m"], src:"/public/img/ambijent/Tepisi/SOLID/THH_SOLID_12CVC.jpg"},
    {title:"15CCC", tag:"SOLID", dimensions: ["2 x 3 m","2 x 1,33m", "1,6 x 2,3 m", "0,8 x 1,5 m", "Staza 0,8 m"], src:"/public/img/ambijent/Tepisi/SOLID/THH_SOLID_15CCC.jpg"},
    {title:"61OEO", tag:"SOLID", dimensions: ["2 x 3 m", "1,6 x 2,3 m", "0,8 x 1,5 m", "Staza 0,8 m", "Staza 1 m"], src:"/public/img/ambijent/Tepisi/SOLID/THH_SOLID_61OEO.jpg"},
    {title:"01EOE", tag:"VEGAS_HOME", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_01EOE.jpg"},
    {title:"04BWB", tag:"VEGAS_HOME", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,2 x 1,7 m",], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_04BWB.jpg"},
    {title:"05EOE", tag:"VEGAS_HOME", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_05EOE.jpg"},
    {title:"11WKY", tag:"VEGAS_HOME", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_11WKY.jpg"},
    {title:"22GSG", tag:"VEGAS_HOME", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_22GSG.jpg"},
    {title:"30GKK", tag:"VEGAS_HOME", dimensions: [ "1,4 x 2 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_30GKK.jpg"},
    {title:"30GKY", tag:"VEGAS_HOME", dimensions: [ "1,4 x 2 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_30GKY.jpg"},
    {title:"62YKE", tag:"VEGAS_HOME", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_Vegas_Home_62YKE.jpg"},
    {title:"81SKS", tag:"VEGAS_HOME", dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_81SKS.jpg"},
    {title:"82WSE", tag:"VEGAS_HOME", dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_82WSE.jpg"},
    {title:"83SGS", tag:"VEGAS_HOME", dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_83SGS.jpg"},
    {title:"C1WWW", tag:"VEGAS_HOME", dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_C1WWW.jpg"},
    {title:"C2EEE", tag:"VEGAS_HOME", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_C2EEE.jpg"},
    {title:"C3GGG", tag:"VEGAS_HOME", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_C3GGG.jpg"},
    {title:"C6YYY", tag:"VEGAS_HOME", dimensions: [ "1,6 x 2,3 m", "1,2 x 1,7 m", "0,8 x 1,5 m"],src:"/public/img/ambijent/Tepisi/VEGAS_HOME/THH_VEGAS_HOME_C6YYY.jpg"},
    {title:"01AAA", tag:"Toscana", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/Toscana/THH_TOSCANA_01AAA.jpg"},
    {title:"01DDD", tag:"Toscana", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/Toscana/THH_TOSCANA_01DDD.jpg"},
    {title:"01KKK", tag:"Toscana", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/Toscana/THH_TOSCANA_01KKK.jpg"},
    {title:"01MMM", tag:"Toscana", dimensions: [ "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m", "0,66 x 1,1 m"], src:"/public/img/ambijent/Tepisi/Toscana/THH_TOSCANA_01MMM.jpg"},
    {title:"01OOO", tag:"Toscana", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/Toscana/THH_TOSCANA_01OOO.jpg"},
    {title:"01RRR", tag:"Toscana", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/Toscana/THH_TOSCANA_01RRR.jpg"},
    {title:"01SSS", tag:"Toscana", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/Toscana/THH_TOSCANA_01SSS.jpg"},
    {title:"01WWW", tag:"Toscana", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/Toscana/THH_TOSCANA_01WWW.jpg"},
    {title:"09WSW", tag:"Toscana", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/Toscana/THH_TOSCANA_09WSW.jpg"},
    {title:"15WOW", tag:"Toscana", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/Toscana/THH_TOSCANA_15WOW.jpg"},
    {title:"16SMS", tag:"Toscana", dimensions: [ "2 x 2,9 m", "1,6 x 2,3 m", "1,4 x 2 m", "0,8 x 1,5 m"], src:"/public/img/ambijent/Tepisi/Toscana/THH_TOSCANA_16SMS.jpg"},
    {title:"06WBG", tag:"VEGAS_KIDS", dimensions: ["1,6 x 2,3 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/VEGAS_KIDS/THH_VEGAS_KIDS_06WBG.jpg"},
    {title:"08YKS", tag:"VEGAS_KIDS", dimensions: ["1,6 x 2,3 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/VEGAS_KIDS/THH_VEGAS_KIDS_08YKS.jpg"},
    {title:"09WBE", tag:"VEGAS_KIDS", dimensions: ["1,6 x 2,3 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/VEGAS_KIDS/THH_VEGAS_KIDS_09WBE.jpg"},
    {title:"10SYE", tag:"VEGAS_KIDS", dimensions: ["1,6 x 2,3 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/VEGAS_KIDS/THH_VEGAS_KIDS_10SYE.jpg"},
    {title:"11YKE", tag:"VEGAS_KIDS", dimensions: ["1,6 x 2,3 m", "1,2 x 1,7 m"], src:"/public/img/ambijent/Tepisi/VEGAS_KIDS/THH_VEGAS_KIDS_11YKE.jpg"}
]

const filtersFirst = [
    {name:"BOHO", title:"BOHO" ,status: true},
    {name:"COLOR", title:"COLOR", status: false},
    {name:"CREATIVE", title:"CREATIVE", status: false},
    {name:"DOLCE_VITA", title:"DOLCE VITA", status: false},
    {name:"GATE", title:"GATE", status: false},
    {name:"LOFT", title:"LOFT", status: false},
    {name:"MONDO", title:"MONDO", status: false},
    {name:"PALERMO", title:"PALERMO", status: false},
    {name:"PASSAGE", title:"PASSAGE", status: false},
    {name:"Pastel", title:"Pastel", status: false},
    {name:"Pastel_Kids", title:"Pastel Kids", status: false},
    {name:"PLAY", title:"PLAY", status: false},
    {name:"PLEASURE", title:"PLEASURE", status: false},
    {name:"PRACTICA", title:"PRACTICA", status: false},
    {name:"SAVANA_plus", title:"SAVANA plus", status: false},
    {name:"SOLID", title:"SOLID", status: false},
    {name:"Toscana", title:"Toscana", status: false},
    {name:"VEGAS_HOME", title:"VEGAS HOME", status: false},
    {name:"VEGAS_KIDS", title:"VEGAS KIDS", status: false},
];

const TepisiComponent = () => {
    const [imgs, setImgs] = useState([...imgsFirst]);
    const [filters, setFilters] = useState([...filtersFirst]);

    useEffect(() => updateImgs(filtersFirst), []);

    const handleFilter = (e) => {
        e.preventDefault();
        let selectedIndex = e.target.value;
        if(!selectedIndex) {
          selectedIndex = e.currentTarget.dataset.index;      
        }
        
        let newFilters = filters.map((item, i) => {
            if (selectedIndex == i) {
              return { ...item, status: true };
            } else {
              return { ...item, status: false };
            }
        });
        setFilters(newFilters);
        updateImgs(newFilters);
    }
 
    const updateImgs = (filtersProperty) => {
        let newImgs = [];
        let a = 0;

        imgsFirst.forEach((img, imgKey) => { 
          filtersProperty.forEach((filter, filterKey) => {  
            if( img.tag == filter.name && filter.status ){
              newImgs[a] = img;
              a++;
            }
          })
        });

        setImgs(newImgs)
    }

    return (
        <div className="galery">
            <FiltersComponent
                onClick={handleFilter}
                handleChange={handleFilter}
                filters={filters} 
            />
            <TepihCards imgs={imgs} />
        </div>
    )
}

if(document.getElementById("tepisi")) {
    const root = createRoot(document.getElementById("tepisi"));
    root.render(<TepisiComponent />);    
}
