import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { FiltersComponent, Cards } from "../utils/galeryutils";

const imgsFirst = [
    {title:"Albus_3", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Albus_3.jpg"},
    {title:"Bavaria_3", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Bavaria_3.jpg"},
    {title:"Block_2", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Block_2.jpg"},
    {title:"Chevron_5", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Chevron_5.jpg"},
    {title:"Chevron_6", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Chevron_6.jpg"},
    {title:"Knox_1", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Knox_1.jpg"},
    {title:"Knox_5", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Knox_5.jpg"},
    {title:"Maison_5", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Maison_5.jpg"},
    {title:"Orleans_3", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Orleans_3.jpg"},
    {title:"Raymond_2", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Raymond_2.jpg"},
    {title:"Raymond_4", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Raymond_4.jpg"},
    {title:"Tobago_2", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Tobago_2.jpg"},
    {title:"Toscana_2", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Toscana_2.jpg"},
    {title:"Toscana_4", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Toscana_4.jpg"},
    {title:"Venezia_2", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Venezia_2.jpg"},
    {title:"Wagner_1", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Wagner_1.jpg"},
    {title:"Wagner_2", tag:"EVOLUTION", src:"/public/img/ambijent/Vinil_Podovi/EVOLUTION/TH_Wagner_2.jpg"},
    {title:"Canasta_3", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/TH_Canasta_3.jpg"},
    {title:"Canasta_4", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/TH_Canasta_4.jpg"},
    {title:"Gres_1", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/TH_Gres_1.jpg"},
    {title:"Gres_3", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/TH_Gres_3.jpg"},
    {title:"Gres_10", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/TH_Gres_10.jpg"},
    {title:"Gres_11", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/TH_Gres_11.jpg"},
    {title:"Gres_12", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/TH_Gres_12.jpg"},
    {title:"Sorbona_2", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/TH_Sorbona_2.jpg"},
    {title:"Sutton_1", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/TH_Sutton_1.jpg"},
    {title:"Wisper_4", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/TH_Wisper_4.jpg"},
    {title:"FORCE_TISSE2", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/THH_FORCE_TISSE2.jpg"},
    {title:"FORCE_TISSE4", tag:"FORCE", src:"/public/img/ambijent/Vinil_Podovi/FORCE/THH_FORCE_TISSE4.jpg"},
    {title:"Broadway_2", tag:"Optima", src:"/public/img/ambijent/Vinil_Podovi/Optima/TH_Broadway_2.jpg"},
    {title:"Madison_1", tag:"Optima", src:"/public/img/ambijent/Vinil_Podovi/Optima/TH_Madison_1.jpg"},
    {title:"Rialto_3", tag:"Optima", src:"/public/img/ambijent/Vinil_Podovi/Optima/TH_Rialto_3.jpg"},
    {title:"Trevi_1", tag:"Optima", src:"/public/img/ambijent/Vinil_Podovi/Optima/TH_Trevi_1.jpg"},
    {title:"Akron_10", tag:"Premium", src:"/public/img/ambijent/Vinil_Podovi/Premium/TH_Akron_10.jpg"},
    {title:"Knox_3", tag:"Premium", src:"/public/img/ambijent/Vinil_Podovi/Premium/TH_Knox_3.jpg"},
    {title:"Knox_4", tag:"Premium", src:"/public/img/ambijent/Vinil_Podovi/Premium/TH_Knox_4.jpg"},
    {title:"Raymond_1", tag:"Premium", src:"/public/img/ambijent/Vinil_Podovi/Premium/TH_Raymond_1.jpg"},
    {title:"Soho_1", tag:"Premium", src:"/public/img/ambijent/Vinil_Podovi/Premium/TH_Soho_1.jpg"},
    {title:"Soho_3", tag:"Premium", src:"/public/img/ambijent/Vinil_Podovi/Premium/TH_Soho_3.jpg"},
    {title:"Soho_8", tag:"Premium", src:"/public/img/ambijent/Vinil_Podovi/Premium/TH_Soho_8.jpg"},
    {title:"PREMIUM_BRENT_1", tag:"Premium", src:"/public/img/ambijent/Vinil_Podovi/Premium/THH_PREMIUM_BRENT_1.jpg"},
]

const filtersFirst = [
    {name:"EVOLUTION", title:"EVOLUTION" ,status: true},
    {name:"FORCE", title:"FORCE", status: false},
    {name:"Optima", title:"Optima", status: false},
    {name:"Premium", title:"Premium", status: false},
];

const VinilpodoviComponent = () => {
    const [imgs, setImgs] = useState([...imgsFirst]);
    const [filters, setFilters] = useState([...filtersFirst]);

    useEffect(() => updateImgs(filtersFirst), []);

    const handleFilter = (e) => {
      e.preventDefault();
      let selectedIndex = e.target.value;
      if(!selectedIndex) {
        selectedIndex = e.currentTarget.dataset.index;      
      }
      
      let newFilters = filters.map((item, i) => {
          if (selectedIndex == i) {
            return { ...item, status: true };
          } else {
            return { ...item, status: false };
          }
      });
      setFilters(newFilters);
      updateImgs(newFilters);
  }
      
 
    const updateImgs = (filtersProperty) => {
        let newImgs = [];
        let a = 0;

        imgsFirst.forEach((img, imgKey) => { 
          filtersProperty.forEach((filter, filterKey) => {  
            if( img.tag == filter.name && filter.status ){
              newImgs[a] = img;
              a++;
            }
          })
        });

        setImgs(newImgs)
    }

    return (
        <div className="galery">
            <FiltersComponent
                onClick={handleFilter}
                handleChange={handleFilter}
                filters={filters} 
            />
            <Cards imgs = {imgs} />
        </div>
    )
}

if(document.getElementById("vinilpodovi")) {
    const root = createRoot(document.getElementById("vinilpodovi"));
    root.render(<VinilpodoviComponent />);    
}
